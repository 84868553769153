import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';
import ExternalLink from '@common/ExternalLink';

const Contact = () => (
  <StaticQuery
    query={graphql`
      query {
        art_story: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "tell_story" }
        ) {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="contact" accent>
        <StyledContainer>
          <div>
            <h1>Contact Us</h1>
            <LogoGrid>
              <ContactGrid>
                <div class="item1">Address:</div>
                <div class="item12">
                  <div>700 Sonne Drive</div>
                  <div>Annapolis MD </div>
                  <div>21401</div>
                </div>
              </ContactGrid>
              <ContactGrid>
                <div class="item2">Phone:</div>
                <div class="item21">(202) 642-6448 </div>
              </ContactGrid>
              <ContactGrid>
                <div class="item3">Email:</div>
                <div class="item31">Contact@Digi-Cyber.com</div>
              </ContactGrid>
            </LogoGrid>
          </div>
          <Art>
            <Img fluid={data.art_story.childImageSharp.fluid} />
          </Art>
        </StyledContainer>
      </Section>
    )}
  />
);

const LogoGrid = styled.div`
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: minmax(150px, auto);
  grid-coloumn-gap: 10px;
  grid a {
    svg {
      width: 100%;
    }
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const ContactGrid = styled.div`
  display: grid;
  .item1 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
  }
  .item2 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
  }
  .item3 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    min-width: 120px;
  }
  .item12 {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 2;
  }
  .item21 {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 2;
  }
  .item31 {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 2;
    grid-column-end: 4;
  }
  a {
    svg {
      width: 100%;
    }
  }

  span {
    margin-left: 10px;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
  }
`;

const StyledContainer = styled(Container) `
  display: flex;
  justify-content: flex-end;
  position: relative;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-content: center;
  }
`;

const Art = styled.figure`
  width: 600px;
  position: absolute;
  top: -12%;
  right: 50%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 0;
    right: 65%;
    width: 500px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

export default Contact;
